import SentryErrorBoundary from 'components/ErrorBoundary'
import { getDefaultMeta } from 'config/meta'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Script from 'next/script'
import React, { Fragment } from 'react'
import { useStore } from 'state'

const MainLayout = dynamic(() => import('layout'))
const Providers = dynamic(() => import('Providers'))

const MyApp = (props: any) => {
  const { pageProps } = props
  const store = useStore(pageProps.initialReduxState)
  const pageMeta = pageProps.seoProps || getDefaultMeta()
  const thirdPartyLibs: string[] = pageProps.thirdPartyLibs || []
  const {
    title,
    ogTitle,
    twitterTitle,
    description,
    ogDescription,
    twitterDescription,
    ogImage,
    twitterImage,
    keyword,
    canonical,
    htmlContent,
  } = pageMeta

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <meta name="theme-color" content="#16191f" />
        {process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_CODE && (
          <meta name="google-site-verification" content={process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_CODE} />
        )}
        <meta property="og:title" content={ogTitle || title} />
        <meta property="og:description" content={ogDescription || description} />
        <meta property="og:url" content="https://hunnyplay.io/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:site_name" content="HunnyPlay" />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={twitterTitle || title} />
        <meta property="twitter:description" content={twitterDescription || description} />
        <meta property="twitter:image" content={twitterImage || ogImage} />
        <meta property="twitter:image:src" content={twitterImage || ogImage} />
        <meta property="keyword" content={keyword} />
        <link rel="canonical" href={canonical} />

        <title>{title}</title>
      </Head>

      <Providers store={store}>
        <App {...props} htmlContent={htmlContent} />
      </Providers>

      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        id="google-analytics"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', '${process.env.NEXT_PUBLIC_GA}');
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        id="coinzilla-tag"
        dangerouslySetInnerHTML={{
          __html: `
          try {
            window.coinzilla_performance = window.coinzilla_performance || [];
            coinzilla_performance.push({ event: "register" });
          } catch (err) { }
          `,
        }}
      />

      <Script
        strategy="afterInteractive"
        id="meta-pixel"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '788573326065240');
          fbq('track', 'PageView');
          `,
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src="https://www.facebook.com/tr?id=788573326065240&ev=PageView&noscript=1"
        />
      </noscript>

      {thirdPartyLibs.map((lib) => (
        <Script src={lib} key={lib} />
      ))}
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
  layoutProps: any
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  pageProps: any
  htmlContent?: string
}

const ProductionErrorBoundary = (
  process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment
) as React.ElementType
const App = ({ Component, pageProps, htmlContent }: AppPropsWithLayout) => {
  const Layout = Component.Layout || MainLayout

  return (
    <ProductionErrorBoundary>
      <Layout {...pageProps} {...pageProps.layoutProps} htmlContent={htmlContent}>
        <Component {...pageProps} />
      </Layout>
    </ProductionErrorBoundary>
  )
}

export default MyApp
