import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import { clusterApiUrl } from '@solana/web3.js'
import { Network } from 'config/types'
import { zeroAddress } from 'viem'
import { Chain } from 'wagmi'

const path = '/images/network'

export const pathNetworkImage = path

type ListNetworkByChainId = {
  [key: string]: Network
}

export enum ChainIdEnum {
  BNB = 56,
  BNB_TESTNET = 97,

  ETH = 1,
  ETH_TESTNET = 11155111,

  OEC = 66,
  OEC_TESTNET = 65,

  AVAX = 43114,
  AVAX_TESTNET = 43113,

  FTM = 250,
  FTM_TESTNET = 4002,

  MATIC = 137,
  MATIC_TESTNET = 80001,

  CELO = 42220,
  CELO_TESTNET = 44787,

  ONE = 1666600000,
  ONE_TESTNET = 1666700000,

  MOVR = 1285,
  MOVR_TESTNET = 1287,

  XDAI = 100,
  XDAI_TESTNET = 77,

  CRO = 25,
  CRO_TESTNET = 338,

  HECO = 128,
  HECO_TESTNET = 256,

  VLX = 106,
  VLX_TESTNET = 111,

  AOA = 1313161554,
  AOA_TESTNET = 1313161555,

  FUSE = 122,
  FUSE_TESTNET = 123,

  ARB = 42161,
  ARB_TESTNET = 421614,

  OP = 10,
  OP_TESTNET = 11155420,

  SOL = -1,
  SOL_TESTNET = -10,

  BTC = -100,
  BTC_TESTNET = -99,

  TRX = -16,
  TRX_TESTNET = -8,

  HPN = -2,

  FIAT = -3,

  TON = -17,
  TON_TESTNET = -34,
}

export const NETWORK_MAP: ListNetworkByChainId = {
  [ChainIdEnum.BNB]: {
    code: 'BSC',
    chainId: ChainIdEnum.BNB,
    rpcCollections: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc-dataseed1.defibit.io/',
      'https://bsc-dataseed1.ninicoin.io/',
    ],
    blockExplorerUrls: 'https://bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain',
      icon: `${path}/bnb.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.BNB_TESTNET]: {
    code: 'BSC_TESTNET',
    chainId: ChainIdEnum.BNB_TESTNET,
    rpcCollections: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: 'https://testnet.bscscan.com',
    blockExplorerName: 'BSC Scan',
    networkInfo: {
      displayName: 'Binance Smart Chain',
      icon: `${path}/bnb.png`,
      shortName: 'BSC',
      native: {
        decimals: 18,
        name: 'BNB',
      },
    },
  },

  [ChainIdEnum.ETH_TESTNET]: {
    code: 'ETH_TEST',
    chainId: ChainIdEnum.ETH_TESTNET,
    rpcCollections: ['https://sepolia.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: 'https://sepolia.etherscan.io/',
    blockExplorerName: 'ETH Goerli Scan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'ETH',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.ETH]: {
    code: 'ETH',
    chainId: ChainIdEnum.ETH,
    rpcCollections: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: 'https://etherscan.io/',
    blockExplorerName: 'Etherscan',
    networkInfo: {
      displayName: 'Ethereum',
      icon: `${path}/eth.png`,
      shortName: 'ETH',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.AVAX]: {
    code: 'AVAX',
    chainId: ChainIdEnum.AVAX,
    rpcCollections: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://snowtrace.io',
    blockExplorerName: 'Snowtrace',
    networkInfo: {
      displayName: 'Avalanche C Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },

  [ChainIdEnum.AVAX_TESTNET]: {
    code: 'AVAX-FUJI',
    chainId: ChainIdEnum.AVAX_TESTNET,
    rpcCollections: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: 'https://testnet.snowtrace.io',
    blockExplorerName: 'Snowtrace (Testnet)',
    networkInfo: {
      displayName: 'Avalanche Juji Chain',
      icon: `${path}/avax.png`,
      shortName: 'Avalanche',
      native: {
        decimals: 18,
        name: 'AVAX',
      },
    },
  },
  [ChainIdEnum.OEC]: {
    code: 'OKEx',
    chainId: ChainIdEnum.OEC,
    rpcCollections: ['https://exchainrpc.okex.org/'],
    blockExplorerUrls: 'https://www.oklink.com/oktc',
    blockExplorerName: 'OKLink',
    networkInfo: {
      displayName: 'OKEx Chain',
      icon: `${path}/okt.png`,
      shortName: 'OKEx',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.OEC_TESTNET]: {
    code: 'OKEx',
    chainId: ChainIdEnum.OEC_TESTNET,
    rpcCollections: ['https://exchaintestrpc.okex.org'],
    blockExplorerUrls: 'https://www.oklink.com/oktc-test',
    blockExplorerName: 'OKLink',
    networkInfo: {
      displayName: 'OKEx Chain',
      icon: `${path}/okt.png`,
      shortName: 'OKEx',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.FTM]: {
    code: 'FTM',
    chainId: ChainIdEnum.FTM,
    rpcCollections: ['https://rpc.ftm.tools'],
    blockExplorerUrls: 'https://ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'FTM',
      native: {
        decimals: 18,
        name: 'OKT',
      },
    },
  },
  [ChainIdEnum.FTM_TESTNET]: {
    code: 'FTM_TESTNET',
    chainId: ChainIdEnum.FTM_TESTNET,
    rpcCollections: ['https://xapi.testnet.fantom.network/lachesis/'],
    blockExplorerUrls: 'https://testnet.ftmscan.com',
    blockExplorerName: 'FTM scan',
    networkInfo: {
      displayName: 'Fantom',
      icon: `${path}/ftm.png`,
      shortName: 'FTM',
      native: {
        decimals: 18,
        name: 'FTM',
      },
    },
  },
  [ChainIdEnum.MATIC]: {
    code: 'MATIC',
    chainId: ChainIdEnum.MATIC,
    rpcCollections: ['https://polygon-rpc.com/'],
    blockExplorerUrls: 'https://polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'POLYGON',
      native: {
        decimals: 18,
        name: 'POL',
      },
    },
  },
  [ChainIdEnum.MATIC_TESTNET]: {
    code: 'MATIC_TESTNET',
    chainId: ChainIdEnum.MATIC_TESTNET,
    rpcCollections: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: 'https://mumbai.polygonscan.com',
    blockExplorerName: 'Polygon scan',
    networkInfo: {
      displayName: 'Polygon',
      icon: `${path}/matic.png`,
      shortName: 'POLYGON',
      native: {
        decimals: 18,
        name: 'POL',
      },
    },
  },
  [ChainIdEnum.CELO]: {
    code: 'CELO',
    chainId: ChainIdEnum.CELO,
    rpcCollections: ['https://forno.celo.org/'],
    blockExplorerUrls: 'https://forno.celo.org/',
    blockExplorerName: 'Celo scan',
    networkInfo: {
      displayName: 'Celo',
      icon: `${path}/celo.png`,
      shortName: 'CELO',
      native: {
        decimals: 18,
        name: 'CELO',
      },
    },
  },
  [ChainIdEnum.CELO_TESTNET]: {
    code: 'CELO_TESTNET',
    chainId: ChainIdEnum.CELO_TESTNET,
    rpcCollections: ['https://alfajores-forno.celo-testnet.org/'],
    blockExplorerUrls: 'https://alfajores-blockscout.celo-testnet.org',
    blockExplorerName: 'CELO scan',
    networkInfo: {
      displayName: 'Celo',
      icon: `${path}/celo.png`,
      shortName: 'CELO',
      native: {
        decimals: 18,
        name: 'CELO',
      },
    },
  },
  [ChainIdEnum.ONE]: {
    code: 'ONE',
    chainId: ChainIdEnum.ONE,
    rpcCollections: ['https://api.harmony.one/'],
    blockExplorerUrls: 'https://explorer.harmony.one',
    blockExplorerName: 'Harmony scan',
    networkInfo: {
      displayName: 'Harmony',
      icon: `${path}/one.png`,
      shortName: 'Harmony',
      native: {
        decimals: 18,
        name: 'ONE',
      },
    },
  },
  [ChainIdEnum.ONE_TESTNET]: {
    code: 'ONE',
    chainId: ChainIdEnum.ONE_TESTNET,
    rpcCollections: ['https://api.s0.b.hmny.io/'],
    blockExplorerUrls: 'https://explorer.pops.one',
    blockExplorerName: 'Harmony scan',
    networkInfo: {
      displayName: 'Harmony',
      icon: `${path}/one.png`,
      shortName: 'Harmony',
      native: {
        decimals: 18,
        name: 'ONE',
      },
    },
  },
  [ChainIdEnum.MOVR]: {
    code: 'MOVR',
    chainId: ChainIdEnum.MOVR,
    rpcCollections: ['https://rpc.moonriver.moonbeam.network/'],
    blockExplorerUrls: 'https://moonriver.moonscan.io',
    blockExplorerName: 'MoonRiver scan',
    networkInfo: {
      displayName: 'MoonRiver',
      icon: `${path}/movr.png`,
      shortName: 'MoonRiver',
      native: {
        decimals: 18,
        name: 'MOVR',
      },
    },
  },
  [ChainIdEnum.MOVR_TESTNET]: {
    code: 'MOVR_TESTNET',
    chainId: ChainIdEnum.MOVR_TESTNET,
    rpcCollections: ['https://rpc.testnet.moonbeam.network/'],
    blockExplorerUrls: 'https://moonriver.moonscan.io',
    blockExplorerName: 'MoonRiver scan',
    networkInfo: {
      displayName: 'MoonRiver',
      icon: `${path}/movr.png`,
      shortName: 'MoonRiver',
      native: {
        decimals: 18,
        name: 'MOVR',
      },
    },
  },
  [ChainIdEnum.XDAI]: {
    code: 'XDAI',
    chainId: ChainIdEnum.XDAI,
    rpcCollections: ['https://rpc.xdaichain.com/'],
    blockExplorerUrls: 'https://blockscout.com/xdai/mainnet',
    blockExplorerName: 'xDai scan',
    networkInfo: {
      displayName: 'xDai',
      icon: `${path}/xdai.png`,
      shortName: 'xDai',
      native: {
        decimals: 18,
        name: 'xDai',
      },
    },
  },
  [ChainIdEnum.XDAI_TESTNET]: {
    code: 'XDAI_TESTNET',
    chainId: ChainIdEnum.XDAI_TESTNET,
    rpcCollections: ['https://sokol.poa.network/'],
    blockExplorerUrls: 'https://blockscout.com/poa/sokol',
    blockExplorerName: 'xDai scan',
    networkInfo: {
      displayName: 'xDai',
      icon: `${path}/xdai.png`,
      shortName: 'xDai',
      native: {
        decimals: 18,
        name: 'xDai',
      },
    },
  },
  [ChainIdEnum.CRO]: {
    code: 'CRO',
    chainId: ChainIdEnum.CRO,
    rpcCollections: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: 'https://cronos.crypto.org/explorer',
    blockExplorerName: 'Cronos scan',
    networkInfo: {
      displayName: 'Cronos',
      icon: `${path}/cro.png`,
      shortName: 'Cronos',
      native: {
        decimals: 18,
        name: 'CRO',
      },
    },
  },
  [ChainIdEnum.CRO_TESTNET]: {
    code: 'CRO_TESTNET',
    chainId: ChainIdEnum.CRO_TESTNET,
    rpcCollections: ['https://cronos-testnet-3.crypto.org:8545/'],
    blockExplorerUrls: 'https://cronos.crypto.org/explorer/testnet3',
    blockExplorerName: 'Cronos scan',
    networkInfo: {
      displayName: 'Cronos',
      icon: `${path}/cro.png`,
      shortName: 'Cronos',
      native: {
        decimals: 18,
        name: 'CRO',
      },
    },
  },
  [ChainIdEnum.HECO]: {
    code: 'HECO',
    chainId: ChainIdEnum.HECO,
    rpcCollections: ['https://http-mainnet.hecochain.com/'],
    blockExplorerUrls: 'https://hecoinfo.com',
    blockExplorerName: 'Houbi scan',
    networkInfo: {
      displayName: 'Houbi ECO Chain',
      icon: `${path}/ht.png`,
      shortName: 'HECO',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.HECO_TESTNET]: {
    code: 'HECO_TESTNET',
    chainId: ChainIdEnum.HECO_TESTNET,
    rpcCollections: ['https://http-testnet.hecochain.com/'],
    blockExplorerUrls: 'https://testnet.hecoinfo.com',
    blockExplorerName: 'Houbi scan',
    networkInfo: {
      displayName: 'Houbi ECO Chain',
      icon: `${path}/ht.png`,
      shortName: 'HECO',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.VLX]: {
    code: 'VLX',
    chainId: ChainIdEnum.VLX,
    rpcCollections: ['https://evmexplorer.velas.com/rpc/'],
    blockExplorerUrls: 'https://evmexplorer.velas.com',
    blockExplorerName: 'Velas scan',
    networkInfo: {
      displayName: 'Velas',
      icon: `${path}/vlx.png`,
      shortName: 'Velas',
      native: {
        decimals: 18,
        name: 'HT',
      },
    },
  },
  [ChainIdEnum.VLX_TESTNET]: {
    code: 'VLX_TESTNET',
    chainId: ChainIdEnum.VLX_TESTNET,
    rpcCollections: ['https://testnet.velas.com/rpc/'],
    blockExplorerUrls: 'https://evmexplorer.testnet.velas.com',
    blockExplorerName: 'Velas scan',
    networkInfo: {
      displayName: 'Velas',
      icon: `${path}/vlx.png`,
      shortName: 'Velas',
      native: {
        decimals: 18,
        name: 'VLX',
      },
    },
  },
  [ChainIdEnum.AOA]: {
    code: 'AOA',
    chainId: ChainIdEnum.AOA,
    rpcCollections: ['https://mainnet.aurora.dev/'],
    blockExplorerUrls: 'https://explorer.mainnet.aurora.dev',
    blockExplorerName: 'Aurora scan',
    networkInfo: {
      displayName: 'Aurora',
      icon: `${path}/aurora.png`,
      shortName: 'Aurora',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.AOA_TESTNET]: {
    code: 'AOA_TESTNET',
    chainId: ChainIdEnum.AOA_TESTNET,
    rpcCollections: ['https://testnet.aurora.dev/'],
    blockExplorerUrls: 'https://explorer.testnet.aurora.dev',
    blockExplorerName: 'Aurora scan',
    networkInfo: {
      displayName: 'Aurora',
      icon: `${path}/aurora.png`,
      shortName: 'Aurora',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.FUSE]: {
    code: 'FUSE',
    chainId: ChainIdEnum.FUSE,
    rpcCollections: ['https://rpc.fuse.io/'],
    blockExplorerUrls: 'https://explorer.fuse.io',
    blockExplorerName: 'Fuse scan',
    networkInfo: {
      displayName: 'Fuse',
      icon: `${path}/fuse.png`,
      shortName: 'Fuse',
      native: {
        decimals: 18,
        name: 'FUSE',
      },
    },
  },
  [ChainIdEnum.FUSE_TESTNET]: {
    code: 'FUSE_TESTNET',
    chainId: ChainIdEnum.FUSE_TESTNET,
    rpcCollections: ['https://rpc.fusespark.io/'],
    blockExplorerUrls: 'https://explorer.fusespark.io',
    blockExplorerName: 'Fuse scan',
    networkInfo: {
      displayName: 'Fuse',
      icon: `${path}/fuse.png`,
      shortName: 'Fuse',
      native: {
        decimals: 18,
        name: 'FUSE',
      },
    },
  },
  [ChainIdEnum.ARB]: {
    code: 'ARB',
    chainId: ChainIdEnum.ARB,
    rpcCollections: ['https://arbitrum-one.publicnode.com'],
    blockExplorerUrls: 'https://arbiscan.io/',
    blockExplorerName: 'ArbiScan',
    networkInfo: {
      displayName: 'Arbitrum One',
      icon: `${path}/arb.png`,
      shortName: 'ARB',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.ARB_TESTNET]: {
    code: 'ARB_TESTNET',
    chainId: ChainIdEnum.ARB_TESTNET,
    rpcCollections: ['https://endpoints.omniatech.io/v1/arbitrum/sepolia/public'],
    blockExplorerUrls: 'https://sepolia.arbiscan.io/',
    blockExplorerName: 'ArbiScan Sepolia',
    networkInfo: {
      displayName: 'Arbitrum Sepolia',
      icon: `${path}/arb.png`,
      shortName: 'ARB',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },

  [ChainIdEnum.OP]: {
    code: 'OP',
    chainId: ChainIdEnum.OP,
    rpcCollections: ['https://optimism-rpc.publicnode.com'],
    blockExplorerUrls: 'https://optimistic.etherscan.io',
    blockExplorerName: 'OP Explorer',
    networkInfo: {
      displayName: 'Optimistic',
      icon: `${path}/op.png`,
      shortName: 'OP',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },
  [ChainIdEnum.OP_TESTNET]: {
    code: 'OP_TESTNET',
    chainId: ChainIdEnum.OP_TESTNET,
    rpcCollections: ['https://optimism-sepolia.blockpi.network/v1/rpc/public'],
    blockExplorerUrls: 'https://sepolia-optimism.etherscan.io',
    blockExplorerName: 'OP Sepolia Scan',
    networkInfo: {
      displayName: 'OP Sepolia',
      icon: `${path}/op.png`,
      shortName: 'OP',
      native: {
        decimals: 18,
        name: 'ETH',
      },
    },
  },

  [ChainIdEnum.SOL]: {
    code: 'SOL',
    chainId: ChainIdEnum.SOL,
    rpcCollections: ['https://mainnet.helius-rpc.com/?api-key=124415ed-3fef-4fe5-bbd5-015d24af3d96'],
    blockExplorerUrls: 'https://solscan.io',
    blockExplorerName: 'SolScan',
    networkInfo: {
      displayName: 'SOLANA',
      icon: `${path}/sol.png`,
      shortName: 'Solana',
      native: {
        decimals: 9,
        name: 'SOL',
      },
    },
  },
  [ChainIdEnum.SOL_TESTNET]: {
    code: 'SOL_TESTNET',
    chainId: ChainIdEnum.SOL_TESTNET,
    rpcCollections: [clusterApiUrl(WalletAdapterNetwork.Testnet)],
    blockExplorerUrls: 'https://solscan.io',
    blockExplorerName: 'SolScan',
    networkInfo: {
      displayName: 'SOLANA',
      icon: `${path}/sol.png`,
      shortName: 'Solana',
      native: {
        decimals: 9,
        name: 'SOL',
      },
    },
  },

  [ChainIdEnum.BTC]: {
    code: 'BTC',
    chainId: ChainIdEnum.BTC,
    rpcCollections: [''],
    blockExplorerUrls: 'https://www.blockchain.com/explorer',
    blockExplorerName: 'Blockchain',
    networkInfo: {
      displayName: 'BTC',
      icon: `${path}/btc.png`,
      shortName: 'BTC',
      native: {
        decimals: 8,
        name: 'BTC',
      },
    },
  },
  [ChainIdEnum.BTC_TESTNET]: {
    code: 'BTC_TESTNET',
    chainId: ChainIdEnum.BTC_TESTNET,
    rpcCollections: [''],
    blockExplorerUrls: 'https://www.blockchain.com/explorer',
    blockExplorerName: 'Blockchain',
    networkInfo: {
      displayName: 'BTC',
      icon: `${path}/btc.png`,
      shortName: 'BTC',
      native: {
        decimals: 8,
        name: 'BTC',
      },
    },
  },

  [ChainIdEnum.TRX]: {
    code: 'TRX',
    chainId: ChainIdEnum.TRX,
    rpcCollections: [''],
    blockExplorerUrls: 'https://tronscan.org/#',
    blockExplorerTxPath: 'transaction',
    blockExplorerName: 'TronScan',
    networkInfo: {
      displayName: 'TRX',
      icon: `${path}/trx.png`,
      shortName: 'TRX',
      native: {
        decimals: 6,
        name: 'TRX',
      },
    },
  },
  [ChainIdEnum.TRX_TESTNET]: {
    code: 'TRX_TESTNET',
    chainId: ChainIdEnum.TRX_TESTNET,
    rpcCollections: [''],
    blockExplorerUrls: 'https://nile.tronscan.org/#',
    blockExplorerTxPath: 'transaction',
    blockExplorerName: 'TronScan',
    networkInfo: {
      displayName: 'TRX',
      icon: `${path}/trx.png`,
      shortName: 'TRX',
      native: {
        decimals: 6,
        name: 'TRX',
      },
    },
  },

  [ChainIdEnum.TON]: {
    code: 'TON',
    chainId: ChainIdEnum.TON,
    rpcCollections: [''],
    blockExplorerUrls: 'https://tonscan.org',
    blockExplorerName: 'TonScan',
    networkInfo: {
      displayName: 'TON',
      icon: `${path}/ton.png`,
      shortName: 'TON',
      native: {
        decimals: 9,
        name: 'TON',
      },
    },
  },
  [ChainIdEnum.TON_TESTNET]: {
    code: 'TON_TESTNET',
    chainId: ChainIdEnum.TON_TESTNET,
    rpcCollections: [''],
    blockExplorerUrls: 'https://testnet.tonscan.org',
    blockExplorerName: 'TonScan',
    networkInfo: {
      displayName: 'TON',
      icon: `${path}/ton.png`,
      shortName: 'TON',
      native: {
        decimals: 9,
        name: 'TON',
      },
    },
  },
}

export const FIAT_NETWORK = {
  code: 'Fiat',
  chainId: ChainIdEnum.FIAT,
  rpcCollections: [],
  blockExplorerUrls: '',
  blockExplorerName: '',
  networkInfo: {
    displayName: 'Fiat',
    icon: `${path}/fiat.png`,
    shortName: 'Fiat',
    native: null,
  },
}

export const SYSTEM_NETWORK_MAP = {
  ...NETWORK_MAP,
  [ChainIdEnum.FIAT]: FIAT_NETWORK,
}

export const NETWORK_UNKNOWN = {
  code: 'UKNOWN',
  chainId: null,
  rpcCollections: [],
  blockExplorerUrls: '',
  blockExplorerName: 'Uknown scan',
  networkInfo: {
    displayName: 'Uknown',
    icon: '',
    shortName: 'Uknown',
    native: {
      decimals: 18,
      name: 'UKNOWN',
    },
  },
}

export const getNetworkInitForWagami = () => {
  return Object.values(NETWORK_MAP)
    .map((network) => {
      if (network.chainId < 0) return null

      return {
        id: network.chainId,
        name: network.networkInfo.displayName,
        network: network.networkInfo.displayName.toLowerCase(),
        nativeCurrency: {
          name: network.networkInfo.native.name,
          symbol: network.networkInfo.native.name,
          decimals: network.networkInfo.native.decimals,
        },
        rpcUrls: {
          public: {
            http: network.rpcCollections,
          },
          default: {
            http: network.rpcCollections,
          },
        },
        blockExplorers: {
          default: {
            name: network.blockExplorerName,
            url: network.blockExplorerUrls,
          },
        },
        contracts: {
          multicall3: {
            address: zeroAddress,
            blockCreated: 0,
          },
        },
      } as Chain
    })
    .filter((item) => item)
}
