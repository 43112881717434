import { createAction } from '@reduxjs/toolkit'
import { FetchingStatus } from 'config/constants'
import { WagerAdventure } from 'config/types/bonus/WagerAdventure'
import { FiatCurrencyConversionRateInUSDType } from 'config/types/fiat'
import { Game, GameDetail } from 'config/types/game'
import { MetaDataResponse, TokenUsdPriceResponse } from 'services/types'

export const initMetadata = createAction<{
  data: MetaDataResponse
}>('app/initMetadata')

export const setMenuOpen = createAction<{ isOpen: boolean }>('app/setMenuOpen')
export const setAuthModalOpen = createAction<{ isOpen: boolean }>('app/setAuthModalOpen')
export const setSearchOpen = createAction<{ isOpen: boolean }>('app/setSearchOpen')
export const setIsSigned = createAction<{ isSigned: boolean; atTime?: number }>('app/setIsSigned')
export const updateTokenUsdPrices = createAction<{ data: TokenUsdPriceResponse }>('app/updateTokenUsdPrices')
export const updatePopularSearchGames = createAction<{ games: Game[] }>('app/updatePopularSearchGames')
export const setIsInGame = createAction<boolean>('app/setIsInGame')
export const setPlayingGame = createAction<{ game: GameDetail }>('app/setPlayingGame')
export const setInitializeSiteStatus = createAction<{ status: FetchingStatus }>('app/setInitializeSiteStatus')
export const updateWagerAdventure = createAction<{ wagerAdventure: WagerAdventure }>('app/updateWagerAdventure')
export const setCurrencyConversionRateInUSD = createAction<{ data: FiatCurrencyConversionRateInUSDType }>(
  'app/setCurrencyConversionRateInUSD',
)

